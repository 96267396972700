/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .modal {
  text-align: center;
  z-index: 1100!important;
}
.altai-theme-content #bodyView .modal-backdrop,
.altai-theme-content #bodyView .modal-backdrop.in {
  opacity: 0.1;
}
@media screen and (min-width: 768px) {
  .altai-theme-content #bodyView .modal:before {
    display: inline-block;
    vertical-align: middle;
    content: " ";
    height: 100%;
  }
}
.altai-theme-content #bodyView .modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-height: calc(100vh - 20px);
  max-width: calc(100vw -20px);
  overflow: auto;
}
.altai-theme-content #bodyView .modal *:focus {
  outline: 0;
  outline: none;
}
.altai-theme-content #bodyView .modal-content {
  z-index: 1100;
  border-radius: 0;
  border: none;
  padding: 10px;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.altai-theme-content #bodyView .modal-content .modal-header {
  border-bottom: none;
}
.altai-theme-content #bodyView .modal-content .modal-footer {
  border-top: 0;
}
.altai-theme-content #bodyView .modal-content .share-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  /* works with row or column */
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.altai-theme-content #bodyView .modal-content .share {
  border-radius: 20px;
  padding: 10px 20px;
  margin: 15px 5px;
  text-decoration: none;
  display: inline-block;
}
.altai-theme-content #bodyView .modal-content .twitter {
  background-color: #00a9f1;
  color: #fff;
}
.altai-theme-content #bodyView .modal-content .linkedin {
  background-color: #007bb6;
  color: #fff;
}
.altai-theme-content #bodyView .modal-content .facebook {
  background-color: #3b5998;
  color: #fff;
}
.altai-theme-content #bodyView .modal-content .googleplus {
  background-color: #DC4E41;
  color: #fff;
}
.altai-theme-content #bodyView .modal-content .mail {
  background-color: #999;
  color: #fff;
}
.altai-theme-content #bodyView .modal-content .description {
  margin: 5px 0 15px 0;
  width: 100%;
}
